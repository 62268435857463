import { format, formatDuration, intervalToDuration, parseISO } from 'date-fns';

import { Job, JobLocationTypeEnum, JobTypeEnum } from '@libs/graphql-types';
import { isNil } from '@libs/helpers/common';
import {
  formatDate,
  formatRate,
  getJobCapacity,
} from '@libs/ui/components/job/utils';

import { DataGridItem } from './types';

const getDuration = (job?: Required<Job>) => {
  if (!job?.start_date || !job?.end_date) {
    return '';
  }

  const duration = intervalToDuration({
    start: parseISO(job.start_date),
    end: parseISO(job.end_date),
  });

  return formatDuration(duration, {
    format: ['years', 'months', 'days', 'hours'],
  });
};

export const mapStriiveJobFields = (job: Job): DataGridItem[] => {
  const striiveJob = job?.striive_job;
  const items = [
    {
      value: job?.category?.name || '',
      title: 'Category',
      icon: 'client',
    },
    {
      value: job?.start_date ? formatDate(job?.start_date) : '',
      icon: 'period',
      title: 'Job start date:',
    },
    {
      value: !isNil(job?.finders_fee)
        ? formatRate({
          min: job?.finders_fee,
          period: 'hour',
        })
        : '',
      icon: 'euro',
      title: 'Finders fee:',
    },
    {
      value: job?.end_date ? formatDate(job?.end_date) : '',
      icon: 'period',
      title: 'Job end date:',
    },
    {
      value: striiveJob?.hours_per_week_min
        ? `${striiveJob?.hours_per_week_min} h/week`
        : '',
      icon: 'clock',
      title: 'Capacity:',
    },
    {
      value: striiveJob?.client_name || '',
      icon: 'client',
      title: 'Client:',
    },
    {
      value: striiveJob?.location || '',
      title: 'City:',
      icon: 'location',
    },
    {
      value: String(striiveJob?.number_of_positions) || '',
      title: 'Number of positions:',
      icon: 'client',
    },
  ];

  return items.filter((i) => i.value) as DataGridItem[];
};

export const mapJobToDataGridItems = (job?: Required<Job>): DataGridItem[] => {
  const isFreelanceJob = job?.type === JobTypeEnum.Freelance;
  const isHibryd = job?.location_type === JobLocationTypeEnum.Hybrid;
  const periodItem: DataGridItem = {
    icon: 'period',
    title: 'Project duration',
    value: getDuration(job),
  };

  const daysInOffice: DataGridItem = {
    icon: 'period',
    title: 'Days in office',
    value: job?.office_hours_per_month,
  };

  return [
    {
      icon: 'period',
      title: 'Job start date',
      value: job?.posted_at
        ? format(parseISO(job.start_date), 'MMM d, yyyy')
        : '',
    },
    ...(isFreelanceJob ? [periodItem] : []),
    ...(isHibryd ? [daysInOffice] : []),
    {
      title: 'Required Capacity',
      value: getJobCapacity(job as Job),
      icon: 'timelapse',
    },
    {
      title: 'Client',
      icon: 'client',
      value: job?.client,
    },
    {
      icon: 'location',
      title: 'Country',
      value: job?.country,
    },
    {
      icon: 'location',
      title: 'City',
      value: job?.city,
    },
  ];
};
