import defaultAvatarPath from 'assets/images/default-avatar.svg';

import {
  JobMatchDeclinationReasonsEnum,
  SkillTypeEnum,
} from '@libs/graphql-types';
import { OptionType } from '@libs/ui/components/form/select';

export const DEFAULT_AVATAR = defaultAvatarPath;

export const SKILL_TYPES_MAP = {
  [SkillTypeEnum.HardSkills]: 'Hard Skills',
  [SkillTypeEnum.Solutions]: 'Solutions',
  [SkillTypeEnum.SoftSkills]: 'Soft Skills',
};
export const INFINITY_SIGN = '∞';

export const OPENTALENT_COMPANY_ID = '1';

export const REJECTION_REASONS: OptionType[] = Object.entries({
  'Does Not Meet Requirements - Experience':
    JobMatchDeclinationReasonsEnum.None,
  'Does Not Meet Requirements - Skills':
    JobMatchDeclinationReasonsEnum.NotEnoughSkills,
  'Does Not Meet Requirements - Qualifications':
    JobMatchDeclinationReasonsEnum.NotEnoughSkills,
  'Does Not Meet Requirements - Salary': JobMatchDeclinationReasonsEnum.None,
  'Does Not Meet Requirements - Visa': JobMatchDeclinationReasonsEnum.None,
  'Position Closed': JobMatchDeclinationReasonsEnum.None,
  'Position Filled': JobMatchDeclinationReasonsEnum.None,
  'Other candidates are better match': JobMatchDeclinationReasonsEnum.None,
  'Duplication of Candidate': JobMatchDeclinationReasonsEnum.None,
  'Candidate Withdrew Application': JobMatchDeclinationReasonsEnum.None,
  'Cultural Fit Concerns or other': JobMatchDeclinationReasonsEnum.None,
}).map(([text, value]) => ({ value, text }));
