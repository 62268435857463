import { useMutation } from '@apollo/client';
import { useMatcherAssignmentsExceeded } from 'hooks/talents';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';
import {
  useMatcherApplication,
  useIsAppliedToSource,
} from 'screens/talent-matcher/source-job/hooks';
import ChatWithClientButton from 'screens/talent/shared/chat-with-client-button';

import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Dialog,
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Zoom,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Job,
  JobMatcherApplicationStatusEnum,
  Mutation,
  MutationCancelApplicationForJobArgs,
  Talent,
} from '@libs/graphql-types';
import { stopEvent } from '@libs/helpers/common';
import Button, { RouterButton } from '@libs/ui/components/button';
import {
  FINDERS_FEE_TOOLTIP,
  formatRate,
  isUnprocessableJob,
} from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

import { WITHDRAW_JOB } from '../../queries';
import { ViewTypes } from '../../types';

interface JobCardFooterProps {
  onJobApply: any;
  onInvite: any;
  job: Job;
  loadJobs?: VoidFunction;
  isOpen?: boolean;
  currentView: ViewTypes;
}

const useStyles = makeStyles((theme) => ({
  dialogClass: {
    padding: '40px',
    width: '500px',

    '& > .MuiDialogTitle-root, .MuiDialogContent-root': {
      padding: 0,
      overflow: 'hidden',
    },

    '& > .MuiDialogActions-root': {
      padding: 0,
    },
  },
  dialogTitle: {
    padding: 0,
    maxWidth: '325px',
    width: '100%',
    margin: '0 auto 24px auto',
    textAlign: 'center',
    lineHeight: '24px',
  },
  dialogContent: {
    padding: 0,
    width: '100%',
    marginBottom: '32px',
  },
  inputTitle: {
    textAlign: 'center',
    lineHeight: '20px',
    marginBottom: '24px',
  },
  withdrowBtn: {
    marginLeft: '8px',
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
  },
  cancelBtn: {
    display: 'none',
  },
  confirmBtn: {
    backgroundColor: theme.palette.warning.main,
    height: '42px',
  },
  applyButtonContainer: {
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}));

const ACTIONS_TEXT_MAP = {
  [ViewTypes.Talent]: 'Something for you, or know someone?',
  [ViewTypes.Matcher]: 'Want to source for this role?',
};

const JobCardFooter = ({
  job,
  onJobApply,
  onInvite,
  loadJobs,
  // isOpen = false,
  currentView,
}: JobCardFooterProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [withdrawReason, setWithdrawReason] = useState('');
  const [dialogOpened, setDialogOpened] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isActionsDisabled = isUnprocessableJob(job);
  const isAssignmentsExceeded = useMatcherAssignmentsExceeded();

  const closeDialog = () => {
    setDialogOpened(false);
  };

  const [withdrawJob] = useMutation<
    Mutation,
    MutationCancelApplicationForJobArgs
  >(WITHDRAW_JOB, {
    onCompleted: () => {
      if (loadJobs) loadJobs();
      enqueueSnackbar('Application successfully withdrawn', {
        variant: 'success',
      });
      setWithdrawReason('');
      closeDialog();
    },
    onError: () => {
      enqueueSnackbar('Error while withdraw an application', {
        variant: 'error',
      });
    },
  });

  const isOldFormat = job.is_old_format;
  const onApplyClick = useCallback(() => {
    if (isOldFormat) {
      onJobApply(job);
    } else {
      history.push(pathManager.talent.jobApply.generatePath({ id: job.id }));
    }
  }, [history, isOldFormat, job, onJobApply]);

  const changeReason = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setWithdrawReason(event.target.value);
    },
    [setWithdrawReason],
  );

  const onConfirmWithdraw = () => {
    withdrawJob({ variables: { job_id: job.id, reason: withdrawReason } });
  };

  const onWithdrawClick = useCallback(async () => {
    setDialogOpened(true);
  }, [setDialogOpened]);

  const isAppliedToSource = useIsAppliedToSource(
    (job?.matchers as Talent[]) || [],
  );

  const matcherApplication = useMatcherApplication(
    job?.job_matcher_applications || [],
  );
  const applicationStatus = isAppliedToSource
    ? 'APPROVED'
    : matcherApplication?.status || 'UNKNOWN';
  const BTN_TEXTS_MAP: Record<JobMatcherApplicationStatusEnum, string> = {
    APPROVED: 'Present candidate',
    REJECTED: 'Applied - rejected',
    CANCELED: 'Applied - canceled',
    REQUESTED: 'Applied - pending',
    UNKNOWN: `Apply to sourcE`,
  };

  const noMoreSource =
    isAssignmentsExceeded &&
    applicationStatus === 'UNKNOWN' &&
    currentView === ViewTypes.Matcher;
  const disabledMatchersBtn =
    ['REJECTED', 'REQUESTED'].includes(applicationStatus) ||
    isActionsDisabled ||
    noMoreSource;

  const applyControls = (
    <div className={classes.applyButtonContainer}>
      {job?.is_applied ? (
        <>
          <Button
            style={{ pointerEvents: 'none' }}
            variant="contained"
            color="error"
            size="small"
            data-test-id="alreadyAppliedButton"
            disabled={isActionsDisabled}
          >
            Applied
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={onWithdrawClick}
            className={classes.withdrowBtn}
            disabled={isActionsDisabled}
          >
            Withdraw
          </Button>
        </>
      ) : (
        <Button
          onClick={onApplyClick}
          variant="contained"
          color="info"
          size="small"
          data-test-id="applyJobButton"
          disabled={isActionsDisabled}
        >
          Apply to job
        </Button>
      )}

      <Button
        disabled={isActionsDisabled}
        size="small"
        onClick={() => onInvite(job)}
        variant="outlined"
      >
        INVITE & EARN
      </Button>
    </div>
  );

  return (
    <div style={{ cursor: 'default' }} onClick={stopEvent}>
      <Box pt={4}>
        <Grid spacing={2} container>
          <Grid item>
            <Typography
              color={noMoreSource ? 'error.main' : undefined}
              paragraph
              variant="caption"
            >
              {noMoreSource
                ? 'The number of positions to which you is assigned as a matcher has been exceeded'
                : ACTIONS_TEXT_MAP[currentView]}{' '}
              {!!job.finders_fee && (
                <Typography variant="caption" component="span">
                  💵 Finder&apos;s fee is{' '}
                  {formatRate({
                    min: job.finders_fee,
                    period: 'hour',
                  })}
                  <Typography
                    component="span"
                    style={{ verticalAlign: 'middle' }}
                  >
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={FINDERS_FEE_TOOLTIP}
                      placement="right"
                    >
                      <InfoIcon
                        style={{ margin: '0 auto -4px' }}
                        fontSize="small"
                        color="disabled"
                      />
                    </Tooltip>
                  </Typography>
                </Typography>
              )}
            </Typography>
          </Grid>

          <Grid flexGrow={1} textAlign="end" justifyContent="flex-end" item>
            <Typography paragraph variant="caption">
              Have a question about this job?
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid spacing={2} component={Box} container>
        <Grid flexGrow={1} item>
          {currentView === ViewTypes.Talent && applyControls}
          {currentView === ViewTypes.Matcher && (
            <Grid spacing={2} container>
              <Grid item>
                <RouterButton
                  to={pathManager.recruiter.sourceJob.generatePath({
                    id: job.id,
                  })}
                  variant="contained"
                  color={noMoreSource ? 'error' : 'info'}
                  size="small"
                  disabled={disabledMatchersBtn}
                >
                  {BTN_TEXTS_MAP[applicationStatus]}
                </RouterButton>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <ChatWithClientButton job={job} disabled={isActionsDisabled} />
        </Grid>
      </Grid>
      <Dialog
        open={dialogOpened}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialogClass,
        }}
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            You are going to withdraw an application
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.dialogContent}>
            <Typography variant="subtitle2" className={classes.inputTitle}>
              Please confirm and write your reason
            </Typography>
            <TextField
              label="Why are you withdrawing the application?"
              variant="filled"
              value={withdrawReason}
              onChange={changeReason}
              multiline
              rows={5}
              fullWidth
              size="small"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.confirmBtn}
            fullWidth
            onClick={onConfirmWithdraw}
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default JobCardFooter;
