import { Formik } from 'formik';
import { useIsEnterpriseCompanyAccount } from 'hooks/company/useCompanyAccountType';
import React from 'react';
import { DefaultModalProps, withLocationStateModal } from 'utils/modals';
import * as yup from 'yup';

import { Alert } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  GetCountiresListDocument,
  TalentCategoriesDocument,
  useGetCurrentUserCompanyTagsQuery,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import { ConnectedCheckbox } from '@libs/ui/components/form/checkbox';
import { ConnectedMultipleSelect } from '@libs/ui/components/form/multiple-select';
import {
  ConnectedGraphSelect,
  OptionType,
} from '@libs/ui/components/form/select';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import { ProfileModals } from '../types';
import { MESSAGE_TYPES } from './consts';
import { useSubmitAction } from './hooks';
import { TalentsCount } from './talents-count';
import { FormModel } from './types';

const useStyles = makeStyles(() => ({
  paper: {
    width: 620,

    '& textarea': {
      resize: 'vertical',
    },
  },
  content: {
    textAlign: 'left',
  },
}));

const validation = yup.object().shape({
  category: yup.string().nullable(),
  messageType: yup.string().nullable().required(),
  message: yup.string().nullable().trim().required(),
});

interface MessageToWorkforceProps { }

const MessageToWorkforce = ({
  isOpen,
  close,
}: DefaultModalProps<MessageToWorkforceProps>) => {
  const classes = useStyles();
  const isEnterprise = useIsEnterpriseCompanyAccount();
  const { onSubmit, loading } = useSubmitAction({ onSuccess: close });
  const { data: tagsData } = useGetCurrentUserCompanyTagsQuery();
  const tagOptions = (tagsData?.currentUserCompanyTags || []).map((i) => ({
    text: i?.name || '',
    value: i?.id || '',
  }));

  return (
    <Formik<FormModel>
      initialValues={{
        category: '',
        messageType: MESSAGE_TYPES.NO_REPLY as any,
        message: '',
        tags: [],
        sendEmail: true,
        isImportant: false,
        country: '',
        subject: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validation}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        console.log('LOG: values', values);
        return (
          <DefaultModal
            handleClose={close}
            open={isOpen}
            title="Message My Community"
            className={classes.paper}
            actions={
              <Grid spacing={4} justifyContent="space-between" container>
                <Grid xs={6} item>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit()}
                    disabled={loading}
                  >
                    Send Message
                  </Button>
                </Grid>
                <Grid xs={6} item>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={close}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            }
          >
            <Box pb={6}>
              {!isEnterprise && (
                <Typography>Send a mass message to My Community.</Typography>
              )}
            </Box>

            <Grid
              className={classes.content}
              container
              spacing={4}
              direction="column"
            >
              <Grid item>
                <ConnectedGraphSelect
                  query={GetCountiresListDocument}
                  dataPath="getCountriesList"
                  dataMap={{ text: 'name', value: 'name' }}
                  hideNoneValue
                  formControlProps={{ size: 'small' }}
                  fullWidth
                  queryOptions={{ variables: { is_europe: true } }}
                  label="Country"
                  name={modelPath<FormModel>((m) => m.country)}
                  variant="filled"
                />
                {/* <GraphConnectedMultipleSelect */}
                {/*   query={GetCountiresListDocument} */}
                {/*   dataMap={{ text: 'name', value: 'name' }} */}
                {/*   dataPath="getCountriesList" */}
                {/*   hideNoneValue */}
                {/*   queryOptions={{ variables: { is_europe: true } }} */}
                {/*   formControlProps={{ size: 'small' }} */}
                {/*   fullWidth */}
                {/*   autoCompleteProps={{ */}
                {/*     isOptionEqualToValue: ( */}
                {/*       option: OptionType, */}
                {/*       value: OptionType, */}
                {/*     ) => { */}
                {/*       return value.value === option.value; */}
                {/*     }, */}
                {/*     filterSelectedOptions: true, */}
                {/*   }} */}
                {/*   inputProps={{ */}
                {/*     variant: 'filled', */}
                {/*     label: 'Select countries', */}
                {/*     placeholder: '', */}
                {/*     margin: 'dense', */}
                {/*     fullWidth: true, */}
                {/*   }} */}
                {/*   helperText="Multiple selections allowed" */}
                {/*   name={modelPath<FormModel>((m) => m.countries)} */}
                {/*   variant="filled" */}
                {/* /> */}
              </Grid>
              {!!tagOptions.length && (
                <Grid item>
                  <ConnectedMultipleSelect
                    fullWidth
                    name={modelPath<FormModel>((m) => m.tags)}
                    variant="filled"
                    options={tagOptions}
                    label="Pick from list"
                    autoCompleteProps={{
                      filterSelectedOptions: true,
                      isOptionEqualToValue: (
                        opt: OptionType,
                        value: OptionType,
                      ) => opt.value === value.value,
                    }}
                    chipProps={{
                      size: 'small',
                    }}
                    inputProps={{
                      variant: 'filled',
                      label: 'Filter by Tags',
                      margin: 'dense',
                    }}
                  />
                </Grid>
              )}
              <Grid item>
                <ConnectedGraphSelect
                  query={TalentCategoriesDocument}
                  dataPath="talentCategories"
                  formControlProps={{ size: 'small' }}
                  fullWidth
                  name={modelPath<FormModel>((m) => m.category)}
                  variant="filled"
                  label="Filter by Category"
                />
              </Grid>
              <Grid item>
                <ConnectedTextField
                  fullWidth
                  variant="filled"
                  label="Subject"
                  name={modelPath<FormModel>((m) => m.subject)}
                />
              </Grid>
              <Grid item>
                <ConnectedCheckbox
                  label="Is important"
                  name={modelPath<FormModel>((m) => m.isImportant)}
                />
              </Grid>
              <Grid item>
                <ConnectedCheckbox
                  label="Send email"
                  name={modelPath<FormModel>((m) => m.sendEmail)}
                />
              </Grid>

              <Grid item>
                <ConnectedTextField
                  fullWidth
                  size="small"
                  variant="filled"
                  onPaste={(e) => {
                    e.persist();
                    e.preventDefault();
                    const currentMessage = values.message;
                    const text = e.clipboardData.getData('text');
                    // Remove extra newlines from the text
                    const cleanedText = text.replace(/\n{3,}/g, '\n\n');

                    // Get the current cursor position
                    let cursorPos: number =
                      (e.nativeEvent?.target as any)?.selectionStart || 0;

                    // Split the textarea content at the cursor position
                    let textBeforeCursor = currentMessage.substring(
                      0,
                      cursorPos,
                    );
                    let textAfterCursor = currentMessage.substring(
                      cursorPos,
                      currentMessage.length,
                    );

                    const newText =
                      textBeforeCursor + cleanedText + textAfterCursor;
                    setFieldValue(
                      modelPath<FormModel>((m) => m.message),
                      newText,
                    );
                  }}
                  multiline
                  label="Enter message"
                  name={modelPath<FormModel>((m) => m.message)}
                  rows={6}
                />
              </Grid>
            </Grid>
            <br />
            <TalentsCount values={values} />

            {loading && (
              <Box mt={4}>
                <Alert color="info">Operation in progress</Alert>
              </Box>
            )}
          </DefaultModal>
        );
      }}
    </Formik>
  );
};

export const MessageToWorkforceModal =
  withLocationStateModal<MessageToWorkforceProps>({
    id: ProfileModals.MessageToWorkforce,
  })(MessageToWorkforce);
